/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import showNotification from '../../commons/helpers/showNotification';
import SuperUserService from '../../services/SuperUserService';
import yup from '../../commons/validators/customYup';
import { FormDateField, FormRadioGroup, FormTextField } from '../../components/forms/FormikFields';

const schema = yup.object().shape({
  email: yup.string().required(),
  date: yup.date().nullable().required(),
});
const EXCLUDE_DELETED = 'EXCLUDE_DELETED';
const INCLUDE_DELETED = 'INCLUDE_DELETED';
const ALL_RECORDS = 'ALL_RECORDS';
const DELETED_RECORDS = { EXCLUDE_DELETED, INCLUDE_DELETED, ALL_RECORDS };

const INIT_VALUES = {
  email: '',
  date: null,
  deletedRecords: DELETED_RECORDS.EXCLUDE_DELETED,
};

// COMPONENT
function CompanyAppointmentSearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [resultsInfo, setResultsInfo] = useState();

  const myHandleSubmit = (values, actions) => {
    const deletedRecords = values.deletedRecords === DELETED_RECORDS.INCLUDE_DELETED ? true : false;
    const allRecords = values.deletedRecords === DELETED_RECORDS.ALL_RECORDS ? true : false;

    setIsLoading(true);
    SuperUserService.getAppointmentReports({
      email: values.email,
      startDate: values.date.format('YYYY-MM-DD'),
      endDate: values.date.format('YYYY-MM-DD'),
      deleted: deletedRecords,
      allRecords: allRecords,
    })
      .then((resp) => {
        if (resp.length === 0) {
          showNotification('warning', 'Nenhum resultado encontrado.');
        }
        setResultsInfo(resp);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Box>
              <Typography>Atalhos</Typography>
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <Button
                  onClick={() => {
                    setFieldValue('date', dayjs().subtract(1, 'day'));
                  }}
                >
                  Ontem ({dayjs().subtract(1, 'day').format('DD/MM/YYYY')})
                </Button>
                <Button
                  onClick={() => {
                    setFieldValue('date', dayjs());
                  }}
                >
                  Hoje ({dayjs().format('DD/MM/YYYY')})
                </Button>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="baseline">
              <FormTextField name="email" label="Email da Empresa" sx={{ minWidth: '300px' }} />
              <FormDateField name="date" label="Data Fim" sx={{ ml: 2, mr: 4 }} />
              <FormRadioGroup
                name="deletedRecords"
                dataSource={[
                  {
                    id: DELETED_RECORDS.EXCLUDE_DELETED,
                    name: 'Excluir Deletados',
                  },
                  {
                    id: DELETED_RECORDS.INCLUDE_DELETED,
                    name: 'Apenas Deletados',
                  },
                  {
                    id: DELETED_RECORDS.ALL_RECORDS,
                    name: 'Todos',
                  },
                ]}
                row
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || isLoading}
                sx={{ ml: 2 }}
              >
                Buscar {(isSubmitting || isLoading) && <CircularProgress />}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {resultsInfo != null && resultsInfo.length > 0 && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Horario</TableCell>
                <TableCell>Profissional</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Telefone Cliente</TableCell>
                <TableCell>Data Criação</TableCell>
                <TableCell>Criado por</TableCell>
                <TableCell>Data Atualização</TableCell>
                <TableCell>Atualizado por</TableCell>
                <TableCell>Data Exclusão</TableCell>
                <TableCell>Excluido por</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultsInfo.map((app) => {
                return (
                  <TableRow key={app.id}>
                    <TableCell>{dayjs(app.date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>
                      {app.startTime} - {app.endTime} ({app.duration} min)
                    </TableCell>
                    <TableCell>{app.professionalName}</TableCell>
                    <TableCell>{app.serviceName}</TableCell>
                    <TableCell>{app.customerName}</TableCell>
                    <TableCell>{app.customerPhone1}</TableCell>
                    <TableCell>
                      {app.createdAt ? dayjs(app.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''}
                    </TableCell>
                    <TableCell>{app.createdByName}</TableCell>
                    <TableCell>
                      {app.updatedAt ? dayjs(app.updatedAt).format('DD/MM/YYYY HH:mm:ss') : ''}
                    </TableCell>
                    <TableCell>{app.updatedByName}</TableCell>
                    <TableCell>
                      {app.deletedAt ? dayjs(app.deletedAt).format('DD/MM/YYYY HH:mm:ss') : ''}
                    </TableCell>
                    <TableCell>{app.deletedByName}</TableCell>
                  </TableRow>
                );
              })}
              {resultsInfo.hasMore && (
                <TableRow>
                  <TableCell>Possui mais pagina de dados</TableCell>
                  <TableCell>Revision: {resultsInfo.revision}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
}

export default CompanyAppointmentSearchPage;
