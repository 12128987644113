/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import { Box, Button, CircularProgress, IconButton, Typography, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import showNotification from '../../commons/helpers/showNotification';
import SuperUserService from '../../services/SuperUserService';
import yup from '../../commons/validators/customYup';
import { FormTextField } from '../../components/forms/FormikFields';
import TransferSubscriptionCompanyCard from './TransferSubscriptionCompanyCard';

const schema = yup.object().shape({
  link: yup.string().required(),
});

const INIT_VALUES = { link: '' };

// COMPONENT
function CompanyOnlineLinkSearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [resultsInfo, setResultsInfo] = useState();

  const myHandleSubmit = (values, actions) => {
    if (!values.link.includes('/')) {
      actions.setSubmitting(false);
      showNotification('error', 'Por favor informe o link completo.');
      return;
    }
    setIsLoading(true);

    const link = values.link;
    const lastSlashIndex = link.lastIndexOf('/');
    const slug = link.substring(lastSlashIndex + 1);

    SuperUserService.getCompanyInfoBySlug(slug)
      .then((resp) => {
        if (resp.length === 0) {
          showNotification('warning', 'Nenhuma empresa encontrada.');
        }
        setResultsInfo(resp);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const handleClean = (setFieldValue) => {
    setResultsInfo(null);
    setFieldValue('link', '');
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Box display="flex" flexDirection="row" alignItems="baseline">
              <FormTextField
                name="link"
                label="Informe o link completo para encontrar a empresa"
                sx={{ minWidth: '400px' }}
                inputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        handleClean(setFieldValue);
                      }}
                    >
                      <FontAwesomeIcon icon="times" />
                    </IconButton>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || isLoading}
                sx={{ ml: 2 }}
              >
                Buscar {(isSubmitting || isLoading) && <CircularProgress />}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {resultsInfo != null && (
        <TransferSubscriptionCompanyCard
          company={resultsInfo.company}
          title="Dados da Empresa"
          extraInfo={
            <>
              {resultsInfo.companyOnlineScheduling && (
                <Box display="flex" flexDirection="row" alignItems="center" sx={{ mt: 1 }}>
                  <Typography component="div" sx={{ mr: 1 }}>
                    Agendamento Online:
                  </Typography>
                  <Link
                    href={`https://online.maapp.com.br/${resultsInfo.companyOnlineScheduling.slug}`}
                    underline="hover"
                    variant="h5"
                    color="info"
                    target="__blank"
                  >
                    {resultsInfo.companyOnlineScheduling.slug}
                  </Link>
                </Box>
              )}

              {resultsInfo.companyPageBio && (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography component="div" sx={{ mr: 1 }}>
                    Page Bio:
                  </Typography>
                  <Link
                    href={`https://maapp.com.br/${resultsInfo.companyPageBio.slug}`}
                    underline="hover"
                    variant="h5"
                    color="info"
                    target="__blank"
                  >
                    {resultsInfo.companyPageBio.slug}
                  </Link>
                </Box>
              )}
            </>
          }
        />
      )}
    </Box>
  );
}

export default CompanyOnlineLinkSearchPage;
