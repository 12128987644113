/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import showNotification from '../../commons/helpers/showNotification';
import SuperUserService from '../../services/SuperUserService';
import yup from '../../commons/validators/customYup';
import { FormTextField } from '../../components/forms/FormikFields';
import AppleSubscriptionDuplicatedModal from './modals/AppleSubscriptionDuplicatedModal';

const schema = yup.object().shape({
  orderId: yup.string().required(),
});

const schemaByOriginalTransactionId = yup.object().shape({
  originalTransactionId: yup.string().required(),
});

// COMPONENT
function AppleOrderSearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState();
  const [modalFindDuplicateOpen, setModalFindDuplicateOpen] = useState({ open: false });

  const myHandleSubmit = (values, actions) => {
    setIsLoading(true);
    SuperUserService.lookupAppleSubscription(values.orderId)
      .then((resp) => {
        if (resp == null) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        }
        setResults(resp);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const myHandleSubmitByOriginalTransactionId = (values, actions) => {
    actions.setSubmitting(false);
    setModalFindDuplicateOpen({
      open: true,
      originalTransactionId: values.originalTransactionId,
    });
  };

  return (
    <Box>
      <AppleSubscriptionDuplicatedModal
        open={modalFindDuplicateOpen.open}
        originalTransactionId={modalFindDuplicateOpen.originalTransactionId}
        onClose={() => {
          setModalFindDuplicateOpen((prev) => ({ ...prev, open: false }));
        }}
      />

      <Box display="flex" flexDirection="row" alignItems="baseline">
        <Formik
          enableReinitialize
          initialValues={{ orderId: '' }}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <FormTextField
                  name="orderId"
                  label="Procure por Order Id"
                  sx={{ minWidth: '300px' }}
                  inputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setResults();
                          setFieldValue('orderId', '');
                        }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || isLoading}
                  sx={{ ml: 2 }}
                >
                  Buscar {(isSubmitting || isLoading) && <CircularProgress />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {/* FORM ORIGINAL TRANSACTION */}
        <Formik
          enableReinitialize
          initialValues={{ originalTransactionId: '' }}
          validationSchema={schemaByOriginalTransactionId}
          onSubmit={myHandleSubmitByOriginalTransactionId}
        >
          {({ isSubmitting, setFieldValue, setFieldTouched }) => (
            <Form>
              <Box display="flex" flexDirection="row" alignItems="baseline" sx={{ ml: 5 }}>
                <FormTextField
                  name="originalTransactionId"
                  label="Procure por Original Transaction Id"
                  sx={{ minWidth: '300px' }}
                  inputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setResults();
                          setFieldValue('originalTransactionId', '');
                          setFieldTouched('originalTransactionId', false);
                        }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || isLoading}
                  sx={{ ml: 2 }}
                >
                  Buscar {(isSubmitting || isLoading) && <CircularProgress />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {results != null && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>transactionId</TableCell>
                <TableCell>originalTransactionId</TableCell>
                <TableCell>webOrderLineItemId</TableCell>
                <TableCell>bundleId</TableCell>
                <TableCell>productId</TableCell>
                <TableCell>subscriptionGroupIdentifier</TableCell>
                <TableCell>purchaseDate</TableCell>
                <TableCell>originalPurchaseDate</TableCell>
                <TableCell>expiresDate</TableCell>
                <TableCell>quantity</TableCell>
                <TableCell>type</TableCell>
                <TableCell>inAppOwnershipType</TableCell>
                <TableCell>signedDate</TableCell>
                <TableCell>offerType</TableCell>
                <TableCell>offerIdentifier</TableCell>
                <TableCell>environment</TableCell>
                <TableCell>revocationReason</TableCell>
                <TableCell>revocationDate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.map((receipt, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{receipt.transactionId}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setModalFindDuplicateOpen({
                            open: true,
                            originalTransactionId: receipt.originalTransactionId,
                          });
                        }}
                      >
                        {receipt.originalTransactionId}
                      </Button>
                    </TableCell>
                    <TableCell>{receipt.webOrderLineItemId}</TableCell>
                    <TableCell>{receipt.bundleId}</TableCell>
                    <TableCell>{receipt.productId}</TableCell>
                    <TableCell>{receipt.subscriptionGroupIdentifier}</TableCell>
                    <TableCell>
                      {receipt.purchaseDate ? dayjs(receipt.purchaseDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell>
                      {receipt.originalPurchaseDate
                        ? dayjs(receipt.originalPurchaseDate).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {receipt.expiresDate ? dayjs(receipt.expiresDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell>{receipt.quantity}</TableCell>
                    <TableCell>{receipt.type}</TableCell>
                    <TableCell>{receipt.inAppOwnershipType}</TableCell>
                    <TableCell>
                      {receipt.signedDate ? dayjs(receipt.signedDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell>{receipt.offerType}</TableCell>
                    <TableCell>{receipt.offerIdentifier}</TableCell>
                    <TableCell>{receipt.environment}</TableCell>
                    <TableCell>{receipt.revocationReason}</TableCell>
                    <TableCell>{receipt.revocationDate}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
}

export default AppleOrderSearchPage;
